import React, { Component } from 'react';
import socketIOClient from 'socket.io-client';
import WidgetContent from './widget/WidgetContent';
import MicButtonWrapper from './MicButtonWrapper';
import MinimizeButtonImage from './MinimizeButtonImage';

import downSampleBuffer from '../utils/downSampleBuffer';
import initialState from '../utils/initialState';
import messageParser from '../utils/messageParser';
import isPreviewPage from '../utils/isPreviewPage';
import changeWidgetPosition from '../utils/changeWidgetPosition';
import textReducer from '../utils/textReducer';
import isSmartPhoneOrTablet from '../utils/isSmartPhoneOrTablet';
import defaultTranslations from '../locales/en';

let ENDPOINT = 'http://localhost:3001/';

if (process.env.NODE_ENV === 'production') {
    ENDPOINT = 'https://widget-backend-dev.eu-gb.mybluemix.net/'; // Development API URL
}
if (!process.env.REACT_APP_IS_DEVELOPMENT_ENV) {
    ENDPOINT = 'https://widget.vocads.com/'; // Live
}

const API = `${ENDPOINT}api/widget/`;

let context;
// let audioContext;
let processor;
let input;
let globalStream;
// let streamStreaming;

class WidgetToggle extends Component {
    constructor() {
        super();
        this.state = initialState;
        this.audioElementRef = React.createRef();
        this.translations = defaultTranslations;
        this.socket = socketIOClient(ENDPOINT);
        this.clearTimeoutId = 0;
        this.analyseVolume = false;
        this.lowVolumeCount = 0;
        this.redirectToken = new URLSearchParams(window.location.search).get('vocads-token');
        this.previewCampaignId = new URLSearchParams(window.location.search).get('widget-preview');
        this.source = isPreviewPage() && this.previewCampaignId ? 'admin' : 'user';
        // this.responseWarning = false;
    }

    componentDidMount() {
        if (this.redirectToken) return this.handleDestinationPageFlow();

        const { widgetId } = this.state;
        return fetch(`${API}get-campaign-token-s2t`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                isPreviewPage() && this.previewCampaignId
                    ? { campaignId: this.previewCampaignId }
                    : { widgetId, location: window.location.href, timestamp: Date() }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Something went wrong!');
                } else {
                    return response.json();
                }
            })
            .then(
                ({
                    campaignId,
                    widgetTitle,
                    triggerAfter,
                    isCampaignActive,
                    bottomSpacing,
                    translations,
                    language,
                    isIframeMode,
                    iframePath
                }) => {
                    if (isIframeMode) {
                        /* Improve the conditional loading of CSS */
                        // eslint-disable-next-line global-require
                        require('../styles/iframeMode.css');
                        const div = document.createElement('div');
                        div.id = 'vocads-iframe-container';
                        const iframe = document.createElement('iframe');
                        iframe.id = 'vocads-iframe';
                        iframe.width = '100%';
                        iframe.height = '100%';

                        iframe.setAttribute('src', iframePath);
                        div.appendChild(iframe);
                        document.body.appendChild(div);
                    }
                    /* defaultTranslations is used as a fallback 
                    in case a translation does not exist in the 
                    backend. */

                    this.translations = { ...defaultTranslations, ...translations };
                    if (!isCampaignActive) return this.setState({ isHidden: true });
                    if (
                        (isPreviewPage() && this.previewCampaignId) ||
                        window.location.href.includes('https://www.vocads.com/black-uni-shoes')
                    ) {
                        localStorage.removeItem(`vocads-${campaignId}`);
                        localStorage.removeItem('vocadsHasInteracted');
                    }
                    changeWidgetPosition(bottomSpacing);
                    const { visiterId, visiterIdGeneratedAt } =
                        JSON.parse(localStorage.getItem(`vocads-${campaignId}`)) || {};
                    const storedDate = new Date(visiterIdGeneratedAt).toUTCString();
                    const localDate = new Date(new Date()).toUTCString();
                    const diffTime = Math.abs(new Date(localDate) - new Date(storedDate));
                    const minutesDifference = Math.ceil(diffTime / 60000);
                    const isLocalVisiterIdValid = minutesDifference < 1440;

                    this.setState({
                        isHidden:
                            !isLocalVisiterIdValid || (isPreviewPage() && this.previewCampaignId),
                        isResuming: isLocalVisiterIdValid,
                        campaignId,
                        widgetTitle,
                        currentQuestion: isLocalVisiterIdValid
                            ? this.translations.resumeConversationMessage
                            : this.translations.loadingText,
                        visiterId,
                        visiterIdGeneratedAt,
                        language: language || 'en',
                        isIframeMode
                    });
                    if (!isLocalVisiterIdValid) {
                        /*
                     if we don't have a visiterId locally or it is expired 
                     or it is a preview page, 
                     only then we display the widget after the timer,
                     else we display the widget instantly
                    */
                        localStorage.removeItem(`vocads-${campaignId}`);
                        console.log(`Vocads widget should load after ${triggerAfter} seconds`);
                        const timer = Number(triggerAfter) * 1000;
                        setTimeout(() => {
                            this.setState({ isHidden: false });
                        }, timer);
                    }

                    this.socket.on('speechData', data => {
                        clearTimeout(this.clearTimeoutId);
                        this.analyseVolume = true;
                        const dataFinal = data.results[0].isFinal;
                        const completeUserResponse = data.results[0].alternatives[0].transcript;
                        const userResponse = textReducer(completeUserResponse);
                        this.setState({ userResponse, completeUserResponse });
                        if (!dataFinal && !this.isVolumeCheckerCalled) {
                            this.lowVolumeCheckerIntervalId = setInterval(() => {
                                if (this.lowVolumeCount >= 6) {
                                    console.log('Skipped due to low mic activity...');
                                    clearInterval(this.volumeAnalyserIntervalId);
                                    this.stopRecording();
                                    this.getNextQuestionAndPlay();
                                }
                            }, 500);
                            this.isVolumeCheckerCalled = true;
                        } else if (
                            dataFinal &&
                            this.lowVolumeCount < 6 &&
                            this.isVolumeCheckerCalled
                        ) {
                            clearInterval(this.lowVolumeCheckerIntervalId);
                            clearInterval(this.volumeAnalyserIntervalId);
                            this.setState({
                                userResponse,
                                completeUserResponse,
                                noResponseCount: 0
                            });
                            this.stopRecording();
                            this.getNextQuestionAndPlay();
                        }
                    });
                }
            )
            .catch(console.log);
    }

    initRecording = () => {
        const { language, options, isDestinationPage } = this.state;
        if (isDestinationPage) return;
        const recordingDeviceType = isSmartPhoneOrTablet() ? 'SMARTPHONE' : 'PC';
        this.socket.emit('startGoogleCloudStream', {
            language,
            labels: options,
            recordingDeviceType
        }); // Init socket Google Speech Connection
        // streamStreaming = true;

        const handleSuccess = stream => {
            const AudioContext = window.AudioContext || window.webkitAudioContext;
            context = new AudioContext();

            const analyser = context.createAnalyser();
            const microphone = context.createMediaStreamSource(stream);
            analyser.smoothingTimeConstant = 0.3;
            analyser.fftSize = 1024;
            // }
            processor = context.createScriptProcessor(2048, 1, 1);
            processor.connect(context.destination);
            context.resume();
            this.clearTimeoutId = setTimeout(this.handleNoUserReponseRecursively, 5000);
            this.setState({ iconState: 'isListening' });
            globalStream = stream;
            input = context.createMediaStreamSource(stream);
            input.connect(processor);
            let volume;
            processor.onaudioprocess = e => {
                if (this.analyseVolume) {
                    microphone.connect(analyser);
                    analyser.connect(processor);
                    const array = new Uint8Array(analyser.frequencyBinCount);
                    analyser.getByteFrequencyData(array);
                    let values = 0;
                    const { length } = array;
                    for (let i = 0; i < length; i++) {
                        values += array[i];
                    }
                    volume = values / length;
                }
                const left = e.inputBuffer.getChannelData(0);
                const left16 = downSampleBuffer(left, 44100, 16000);
                this.socket.emit('binaryData', left16);
            };
            this.volumeAnalyserIntervalId = setInterval(() => {
                if (volume < 5) {
                    this.lowVolumeCount++;
                }
                if (volume < 10) {
                    clearTimeout(this.clearTimeoutId);
                }
            }, 500);
        };
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(handleSuccess)
            .catch(() => console.log('Microphone access denied or no microphone found.'));
    };

    getFirstQuestionAndPlay = () => {
        const { campaignId, language } = this.state;
        const { coupon, message } =
            JSON.parse(localStorage.getItem(`vocads-${campaignId}-coupon`)) || {};
        if (coupon) {
            return this.setState({ isCoupon: coupon, currentQuestion: message, isEnd: true }, () =>
                this.playMessageFromText(messageParser(message))
            );
        }
        const requestOptions = {
            source: this.source,
            campaignId,
            language
        };
        return this.conversationApiHandler(requestOptions, true);
    };

    getNextQuestionAndPlay = () => {
        clearTimeout(this.clearTimeoutId);
        this.lowVolumeCount = 0;
        this.isVolumeCheckerCalled = false;
        this.analyseVolume = false;
        // get next questions
        let requestOptions;
        const {
            campaignId,
            completeUserResponse,
            visiterId,
            isResuming,
            visiterIdGeneratedAt,
            language
        } = this.state;
        if (isResuming) {
            const { currentQuestion, visiterId: storedVisiterId } = JSON.parse(
                localStorage.getItem(`vocads-${campaignId}`)
            );
            requestOptions = {
                source: this.source,
                campaignId,
                visiterId: storedVisiterId,
                resume: true,
                resumeQuestion: currentQuestion,
                resumeMessage: completeUserResponse,
                language
            };
            const vocadsLocalStorage = JSON.stringify({
                currentQuestion, // do not store resume conversation message in the localStorage
                visiterId: storedVisiterId,
                visiterIdGeneratedAt
            });
            localStorage.setItem(`vocads-${campaignId}`, vocadsLocalStorage);
        } else {
            this.setState({ isFirstQuestion: false });
            requestOptions = {
                source: this.source,
                campaignId,
                visiterId,
                message: completeUserResponse,
                language
            };
        }
        this.conversationApiHandler(requestOptions, false);
    };

    conversationApiHandler = (requestOptions, isFirstQuestion) => {
        fetch(`${API}campaign-conversation`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestOptions)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Something went wrong!');
                } else {
                    return response.json();
                }
            })
            .then(
                ({
                    visiterId,
                    message,
                    type,
                    warningType,
                    isEnd,
                    url,
                    token,
                    coupon,
                    generatedAt,
                    options
                }) => {
                    const {
                        campaignId,
                        currentQuestion,
                        visiterIdGeneratedAt,
                        visiterId: previousVisiterId,
                        options: prevOptions
                    } = this.state;
                    if (coupon) {
                        localStorage.setItem(
                            `vocads-${campaignId}-coupon`,
                            JSON.stringify({ coupon, message })
                        );
                        localStorage.removeItem(`vocads-${campaignId}`);
                    }
                    let { isResuming } = this.state;
                    const messageToSpeak = messageParser(
                        type ? [this.translations.warnings[warningType]] : message
                    );
                    const { visiterId: storedVisiterId } =
                        JSON.parse(localStorage.getItem(`vocads-${campaignId}`)) || {};
                    /*
                this.responseWarning = type && isResuming;
                */
                    if (!(type && isResuming) && !isFirstQuestion) {
                        this.setState({
                            isResuming: false,
                            isFirstQuestion: false,
                            options
                        });
                        /*
                        set isResuming var to false as is not being accessed from the variable
                    */
                        isResuming = false;
                    }
                    let optionToDisplay = options;
                    if (type) {
                        optionToDisplay = prevOptions;
                    }
                    if (isResuming) {
                        optionToDisplay = null;
                    }
                    this.setState({
                        visiterId: isResuming ? storedVisiterId : previousVisiterId || visiterId,
                        isEnd,
                        iconState: 'isSpeaking',
                        currentQuestion: type || isResuming ? currentQuestion : message,
                        isResponseWarning: !!type,
                        userResponse: null,
                        isCoupon: coupon,
                        visiterIdGeneratedAt: generatedAt || visiterIdGeneratedAt,
                        options: optionToDisplay
                    });

                    if (isFirstQuestion) {
                        this.setState({
                            isFirstQuestion,
                            isResponseWarning: !!type
                        });
                        this.playMessageFromText(
                            isResuming
                                ? this.translations.resumeConversationMessage
                                : messageToSpeak,
                            url,
                            token
                        );
                    } else {
                        if (storedVisiterId && storedVisiterId !== visiterId) {
                            /*
                             * user does not want to resume the conversation,
                             * clean up local storage & reinitialize
                             */

                            localStorage.removeItem(`vocads-${campaignId}`);
                            this.setState({
                                isResuming: false,
                                isFirstQuestion,
                                visiterId,
                                options: type ? prevOptions : options
                            });
                        }

                        this.playMessageFromText(messageToSpeak, url, token, coupon);
                    }
                }
            )
            .catch(console.log);
    };

    stopRecording = () => {
        clearTimeout(this.initRecordingTimerID);
        clearTimeout(this.clearTimeoutId);
        if (!globalStream || !input) return;
        // streamStreaming = false;
        this.socket.emit('endGoogleCloudStream', '');
        const track = globalStream.getTracks()[0];
        track.stop();
        input.disconnect(processor);
        processor.disconnect(context.destination);
        context.close().then(() => {
            context = null;
            input = null;
            processor = null;
        });
    };

    playMessageFromText = (messageToSpeak, url, token, coupon) => {
        clearInterval(this.lowVolumeCheckerIntervalId);
        const {
            isDestinationPage,
            isEnd,
            campaignId,
            widgetTitle,
            isCoupon,
            language,
            currentQuestion,
            isIframeMode
        } = this.state;
        const params = `text=${messageToSpeak}&language=${language}`;
        const audio = this.audioElementRef.current;
        if (!audio) return console.log('No audio element found.');
        audio.setAttribute('type', 'audio/mpeg');
        audio.setAttribute('src', `${API}t2s-synthesize?${params}`);
        audio.play();
        let isAudioDurationInvalid = true;
        let skipAudioOnEndedEvent = false;
        audio.onloadedmetadata = () => {
            if (
                Number.isFinite(audio.duration) &&
                messageToSpeak !== this.translations.speakSomethingMessage
            ) {
                isAudioDurationInvalid = false;
                const timeGap = 0.3;
                this.initRecordingTimerID = setTimeout(() => {
                    if (url && isIframeMode) {
                        skipAudioOnEndedEvent = true;
                        const iframe = document.getElementById('vocads-iframe');
                        if (iframe) {
                            iframe.setAttribute('src', url);
                        }
                        return this.getNextQuestionAndPlay();
                    }
                    if (!isEnd) return this.initRecording();
                }, (audio.duration - timeGap) * 1000);
            }
        };
        audio.onended = () => {
            if (skipAudioOnEndedEvent) return;

            if (isDestinationPage) {
                return this.setState({ isOpen: false });
            }
            if (url && isIframeMode) {
                const iframe = document.getElementById('vocads-iframe');
                if (iframe) {
                    iframe.setAttribute('src', url);
                }
                return this.getNextQuestionAndPlay();
            }
            if (url && !coupon && !isIframeMode) {
                this.setState({ isOpen: false }, () => {
                    window.location.href = token ? `${url}?vocads-token=${token}` : url;
                });
                return null;
            }

            if (isEnd) {
                // this.stopRecording();
                return this.setState({
                    ...initialState,
                    currentQuestion: isCoupon ? currentQuestion : this.translations.loadingText,
                    isOpen: !!isCoupon,
                    campaignId,
                    widgetTitle,
                    isHidden: false,
                    isCoupon,
                    language
                });
            }
            if (isAudioDurationInvalid) {
                // fallback when audio duration is unknown
                console.log('Audio duration not found...');
                return this.initRecording();
            }
        };
    };

    pauseConversation = () => {
        this.stopRecording();
        const {
            isFirstQuestion,
            isResponseWarning,
            isResuming,
            isEnd,
            isDestinationPage,
            currentQuestion,
            visiterId,
            campaignId,
            isCoupon,
            visiterIdGeneratedAt,
            language
        } = this.state;
        if (
            !isFirstQuestion &&
            !isResponseWarning &&
            !isResuming &&
            !isEnd &&
            !isDestinationPage &&
            !isCoupon &&
            currentQuestion !== this.translations.loadingText
        ) {
            this.setState({
                isResuming: true,
                currentQuestion: this.translations.resumeConversationMessage,
                noResponseCount: 0
            });
            const vocadsLocalStorage = JSON.stringify({
                currentQuestion,
                visiterId,
                visiterIdGeneratedAt
            });
            localStorage.setItem(`vocads-${campaignId}`, vocadsLocalStorage);
        }
        this.setState({ isOpen: false, language });
    };

    handleNoUserReponseRecursively = () => {
        this.analyseVolume = false;
        clearInterval(this.volumeAnalyserIntervalId);
        this.lowVolumeCount = 0;
        this.isVolumeCheckerCalled = false;
        this.stopRecording();
        const { noResponseCount } = this.state;
        if (noResponseCount >= 3) {
            this.setState({ isEnd: true, iconState: 'isSpeaking' });
            this.playMessageFromText(this.translations.warnings.noResposeEndCampaignMessage);
        } else {
            this.setState(prevState => ({
                noResponseCount: prevState.noResponseCount + 1,
                iconState: 'isSpeaking'
            }));
            this.playMessageFromText(this.translations.speakSomethingMessage);
        }
    };

    handleDestinationPageFlow = () => {
        fetch(`${API}get-token-message/${this.redirectToken}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Something went wrong. Probably the token is invalid.');
                } else {
                    return response.json();
                }
            })
            .then(({ message, title }) => {
                const messageToSpeak = messageParser([message]);
                setTimeout(() => {
                    this.setState(
                        {
                            isDestinationPage: true,
                            currentQuestion: messageToSpeak,
                            isOpen: true,
                            isHidden: false,
                            widgetTitle: title
                        },
                        () => this.playMessageFromText(messageToSpeak)
                    );
                }, 1500);
            })
            .catch(console.log);
    };

    render() {
        const {
            isHidden,
            isOpen,
            widgetTitle,
            iconState,
            currentQuestion,
            isDestinationPage,
            isCoupon,
            userResponse,
            isFirstQuestion,
            options
        } = this.state;
        if (isHidden) return null;
        return isOpen ? (
            <React.Fragment>
                <audio ref={this.audioElementRef} id="audio" hidden controls="controls">
                    Your browser does not support the audio element.
                </audio>
                <WidgetContent
                    isFirstQuestion={isFirstQuestion}
                    widgetTitle={widgetTitle}
                    userResponse={userResponse}
                    iconState={iconState}
                    currentQuestion={currentQuestion}
                    audioElementRef={this.audioElementRef}
                    coupon={isCoupon}
                    allowMicAccessText={this.translations.allowMicAccessText}
                    talkingToYouText={this.translations.talkingToYouText}
                    listentingToYouText={this.translations.listentingToYouText}
                    options={options}
                />
                <MinimizeButtonImage onClick={this.pauseConversation} />
            </React.Fragment>
        ) : (
            <React.Fragment>
                {localStorage.getItem('vocadsHasInteracted') ? null : (
                    <div className="vocads-widget-animated-circle" />
                )}
                <MicButtonWrapper
                    micButtonTooltip={this.translations.micButtonTooltip}
                    isMain
                    onClick={() =>
                        this.setState({ isOpen: true }, () => {
                            if (!localStorage.getItem('vocadsHasInteracted')) {
                                localStorage.setItem('vocadsHasInteracted', true);
                            }
                            return isDestinationPage
                                ? this.handleDestinationPageFlow()
                                : this.getFirstQuestionAndPlay();
                        })
                    }
                />
            </React.Fragment>
        );
    }
}

export default WidgetToggle;
